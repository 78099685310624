// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    admins: [],
};

const slice = createSlice({
    name: 'adminUsers',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getUserListSuccess(state, action) {
            state.admins = action.payload;
        },
        getUserServiceTokenSuccess(state, action) {
            state.jwt = action.payload;
        }
    }
});

export default slice.reducer;

export function getUserList(params) {
    return async () => {
        try {
            let url = '/api/users/' + (params.roleFilter ? params.roleFilter : "ALL");
            if(params) {
                const urlParams = new URLSearchParams(Object.entries(params));
                url += `?${urlParams.toString()}`;
            }
            const response = await axios.get(url);
            dispatch(slice.actions.getUserListSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserServiceToken(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/users/loginas/${id}`);
            if(response.status === 200) {
                // window.location.href = '/';
                if(response.data && response.data.success) {
                    dispatch(slice.actions.getUserServiceTokenSuccess(response.data.serviceToken));   
                } else {
                    dispatch(slice.actions.hasError(response.data.message));
                }
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateAdmin(id, firstname, lastname, email, username, role, iban, bic, title) {
    return async () => {
        try {
            const response = await axios.put(`/api/users/${id}`, {
                firstname,
                lastname,
                email,
                username,
                role,
                iban, 
                bic,
                title
            });
            if(response.status === 200) {
                dispatch(getUserList());
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const createUser = (firstname, lastname, email, username, password, role, iban, bic, title) => async () => {
    try {
        const response = await axios.post('/api/users', {
            firstname,
            lastname,
            email,
            username,
            password,
            role,
            iban,
            bic,
            title
        });
        if(response.status === 200) {
            dispatch(getUserList());
        } else {
            dispatch(slice.actions.hasError(response.data.message));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};

export function updateAdminPassword(id, password) {
    return async () => {
        try {
            const response = await axios.put(`/api/users/${id}/password`, {
                password
            });
            if(response.status === 200) {
                dispatch(getUserList());
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}