// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    chats: [],
    user: {},
    users: [],
    filters: [],
    templates: [],
    gqueue: [],
    queue: [],
    suggestion: {}
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.user = action.payload;
        },

        // GET USER CHATS
        getUserChatsSuccess(state, action) {
            state.chats = action.payload;
        },

        getUserQueueSuccess(state, action) {
            state.queue = action.payload;
        },

        getQueueSuccess(state, action) {
            state.gqueue = action.payload;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.users = action.payload;
        },

        // Get filters
        getFiltersSuccess(state, action) {
            state.filters = action.payload;
        },

        // Get templates
        getTemplatesSuccess(state, action) {
            state.templates = action.payload;
        },

        getChatSuggestionsSuccess(state, action) {
            state.suggestion = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUser(id) {
    return async () => {
        try {
            const response = await axios.post('/api/chat/users/id', { id });
            dispatch(slice.actions.getUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserChats(user) {
    return async () => {
        try {
            const response = await axios.post(`/api/chat/${user}`, { user });
            dispatch(slice.actions.getUserChatsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Translate message
export function translateMessage(messageId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get('/api/chat/translate/' + messageId);
            resolve(response.data.message);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            reject(error);
        }
    });
}

export function getUserQueue(user) {
    return async () => {
        try {
            const response = await axios.get(`/api/chat/${user}/queue`, { user });
            dispatch(slice.actions.getUserQueueSuccess(response.data.queue));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGlobalQueue() {
    return async () => {
        try {
            const response = await axios.get('/api/chat/queue', {});
            dispatch(slice.actions.getQueueSuccess(response.data.gqueue));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function retryQueue(id) {
    return new Promise(async (resolve, reject) => {
        try {
            await axios.get(`/api/chat/${id}/queue/retry`, {});
            resolve(true);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            reject(false);
        }
    });
}

export function approveReservation(conversation) {
    return async () => {
        try {
            await axios.post(`/api/chat/${conversation}/approve`, {});
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function approveHostifyReservation(conversation) {
    return async () => {
        try {
            const approved = await axios.get(`/api/hostify/message/${conversation}/preapprove`, {});
            return approved.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function insertChat(chat) {
    return async () => {
        try {
             switch(chat.source) {
                case 'sms':
                    await axios.post(`/api/sms/${chat.to}`, {
                        message: chat.text,
                        person: chat.from
                    });
                    break;
                case 'whatsapp':
                    await axios.post(`/api/whatsapp/${chat.to}`, {
                        message: chat.text,
                        person: chat.from
                    });
                    break;
                default:
                    await axios.post('/api/chat/insert', chat);
                    dispatch(getUserQueue(chat.to));
                    break;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function sendHostifyMessage(conversation, message) {
    return async () => {
        try {
            await axios.post(`/api/hostify/message/${conversation}/send`, { message });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getChatSuggestion(conversation, instructions) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get(`/api/assistants/suggest/${conversation}?instructions=${instructions}`);
            dispatch(slice.actions.getChatSuggestionsSuccess(response.data.messages));
            return resolve(response.data.messages);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return reject(error);
        }
    });
}

export function markAsRead(conversation) {
    return async () => {
        try {
            await axios.get(`/api/chat/${conversation}/markasread`, {});
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFilters() {
    return async () => {
        try {
            const response = await axios.get('/api/chat/filters');
            dispatch(slice.actions.getFiltersSuccess(response.data.filters));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUsers(search, label, unreadMessages) {
    return async () => {
        try {
            let url = `/api/chat/users?t=${new Date().getTime()}`
            if(search) url += `&q=${search}`
            if(label) url += `&l=${label}`
            if(unreadMessages) url += `&u=${unreadMessages}`
            const response = await axios.get(url);
            dispatch(slice.actions.getUsersSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTemplates(room) {
    return async () => {
        try {
            const response = await axios.get(`/api/templates${room ? '/' + room : ''}`);
            dispatch(slice.actions.getTemplatesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTemplateTranslation(id, lang) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get(`/api/templates//translate/${id}/${lang}`);
            resolve(response.data.translation);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            reject(error);
        }
    });
}