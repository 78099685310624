// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconApps, IconMoneybag, IconBuildingBank, IconFileEuro, IconReceiptTax, IconPigMoney } from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconMoneybag,
    IconBuildingBank,
    IconFileEuro,
    IconReceiptTax,
    IconPigMoney
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const accounting = {
    id: 'accounting',
    title: <FormattedMessage id="accounting" />,
    icon: icons.IconApps,
    type: 'group',
    roles: ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_AGENT'],
    children: [
        {
            id: 'control',
            title: <FormattedMessage id="control" />,
            type: 'item',
            roles: ['ROLE_ADMIN'],
            icon: icons.IconMoneybag,
            url: '/app/accounting/control'
        },
        {
            id: 'accountancy',
            title: <FormattedMessage id="accountancy" />,
            type: 'item',
            roles: ['ROLE_OWNER'],
            icon: icons.IconBuildingBank,
            url: '/app/accounting/accountancy'
        },
        {
            id: 'accountancy',
            title: <FormattedMessage id="accountancy" />,
            type: 'collapse',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'accountancy',
                    title: <FormattedMessage id="accountancy" />,
                    type: 'item',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                    icon: icons.IconBuildingBank,
                    url: '/app/accounting/accountancy'
                },
                {
                    id: 'advances',
                    title: <FormattedMessage id="advances" />,
                    type: 'item',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                    icon: icons.IconFileEuro,
                    url: '/app/accounting/advances'
                },
                {
                    id: 'staytax',
                    title: <FormattedMessage id="staytax" />,
                    type: 'item',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                    icon: icons.IconReceiptTax,
                    url: '/app/accounting/stay-tax'
                },
                {
                    id: 'transfers',
                    title: <FormattedMessage id="transfers" />,
                    type: 'item',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                    icon: icons.IconPigMoney,
                    url: '/app/accounting/transfers'
                },
            ]
        },
    ]
};

export default accounting;
