// third-party
import { createSlice } from '@reduxjs/toolkit';


// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    rooms: [],
    room: {},
    listings: [],
    fees: [],
    otas: [],
    owners: [],
    notes: [],
    tags: [],
};

const slice = createSlice({
    name: 'roomsControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Planning
        getRoomsSuccess(state, action) {
            state.rooms = action.payload;
        },

        getOtasSuccess(state, action) {
            state.otas = action.payload;
        },

        getRoomSuccess(state, action) {
            state.room = action.payload;
        },

        getRoomDetailsSuccess(state, action) {
            state.roomDetails = action.payload;
        },

        getListingsSuccess(state, action) {
            state.listings = action.payload;
        },

        getOwnersSuccess(state, action) {
            state.owners = action.payload
        },

        getFeesSuccess(state, action) {
            state.fees = action.payload
        },

        getNotesSuccess(state, action) {
            state.notes = action.payload
        },
        getTagsSuccess(state, action) {
            state.tags = action.payload
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRooms(search, showRemoved) {
    return async () => {
        try {
            let queryParams = new URLSearchParams();
            if(search) queryParams.append('search', search);
            if(showRemoved) queryParams.append('show_removed', showRemoved);
            const response = await axios.get(`/api/rooms${queryParams.toString() ? '?' + queryParams.toString() : ''}`);
            dispatch(slice.actions.getRoomsSuccess(response.data.rooms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const getTags = () => async () => {
    try {
        const response = await axios.get(`/api/rooms/tags`);
        dispatch(slice.actions.getTagsSuccess(response.data.tags));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export function getOtas() {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/otas`);
            dispatch(slice.actions.getOtasSuccess(response.data.otas));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoom(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/${id}`);
            dispatch(slice.actions.getRoomSuccess(response.data.room));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoomDetails(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/${id}/amenities`);
            dispatch(slice.actions.getRoomDetailsSuccess(response.data.listing));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetRoom() {
    return async () => {
        try {
            dispatch(slice.actions.getRoomSuccess({}));
            dispatch(slice.actions.hasError(null));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeRoom(id) {
    return async () => {
        try {
            const response = await axios.delete(`/api/rooms/${id}`);
            if(response.data.success) {
                dispatch(slice.actions.getRoomSuccess(response.data.room));
            } else {
                dispatch(slice.actions.hasError(response.data.message));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const updateRoom = (id, data) => async () => {
    try {
        const response = await axios.put(`/api/rooms/${id}`, data);
        if(response.data.success) {
            dispatch(slice.actions.getRoomSuccess(response.data.room));
        } else {
            dispatch(slice.actions.hasError(response.data.message));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const duplicateRoom = (id) => async () => {
    try {
        const response = await axios.post(`/api/rooms/${id}/duplicate`);
        if(response.data.success) {
            return response.data.room;
        } else {
            dispatch(slice.actions.hasError(response.data.message));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const updateRoomAddress = (id, data) => async () => {
    try {
        const response = await axios.put(`/api/rooms/${id}/address`, data);
        if(response.data.success) {
            dispatch(slice.actions.getRoomSuccess(response.data.room));
        } else {
            dispatch(slice.actions.hasError(response.data.message));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const updateRoomFees = (id, data) => async () => {
    try {
        const response = await axios.put(`/api/rooms/${id}/fees`, data);
        if(response.data.success) {
            dispatch(slice.actions.getRoomSuccess(response.data.room));
        } else {
            dispatch(slice.actions.hasError(response.data.message));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const updateRoomInvoices = (id, data) => async () => {
    try {
        const response = await axios.put(`/api/rooms/${id}/invoices`, data);
        if(response.data.success) {
            dispatch(slice.actions.getRoomSuccess(response.data.room));
        } else {
            dispatch(slice.actions.hasError(response.data.message));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export function getOwners() {
    return async () => {
        try {
            const response = await axios.get(`/api/users/OWNERS?limit=1000`);
            dispatch(slice.actions.getOwnersSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getListings() {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/listings`);
            dispatch(slice.actions.getListingsSuccess(response.data.listings));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFees() {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/fees`);
            dispatch(slice.actions.getFeesSuccess(response.data.fees));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateListing(data) {
    return async () => {
        try {
            const response = await axios.post(`/api/rooms/listings`, data);
            dispatch(slice.actions.getListingsSuccess(response.data.listings));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoomNotes(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/${id}/notes`);
            dispatch(slice.actions.getNotesSuccess(response.data.notes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addRoomNote(id, data) {
    return async () => {
        try {
            const response = await axios.put(`/api/rooms/${id}/notes`, {note: data});
            dispatch(slice.actions.getNotesSuccess(response.data.notes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeRoomNote(id, noteId) {
    return async () => {
        try {
            const response = await axios.delete(`/api/rooms/${id}/notes/${noteId}`);
            dispatch(slice.actions.getNotesSuccess(response.data.notes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editRoomNote(id, noteId, data) {
    return async () => {
        try {
            const response = await axios.post(`/api/rooms/${id}/notes/${noteId}`, {note: data});
            dispatch(slice.actions.getNotesSuccess(response.data.notes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const getIntructionsTranslations = (id, lang, force) => async () => {
    try {
        const response = await axios.get(`/api/rooms/${id}/instructions/${lang}${force ? '?force=true' : ''}`);
        return response.data.instructions;
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const updateTranslation = (id, lang, translation) => async () => {
    try {
        const response = await axios.post(`/api/rooms/${id}/instructions/${lang}`, {translation});
        return response.data.instructions;
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const updateInstructions = (id, instructions) => async () => {
    try {
        const response = await axios.post(`/api/rooms/${id}/instructions`, {instructions});
        return response.data.instructions;
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const updateGptInstructions = (id, instructions) => async () => {
    try {
        const response = await axios.post(`/api/rooms/${id}/instructions/gpt`, {instructions});
        return response.data.instructions;
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}