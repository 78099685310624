// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    list: {},
    owners: [],
    advances: [],
    ownerEmails: [],
    taxes: {}
};

const slice = createSlice({
    name: 'accountancy',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET account list
        getAccountancySuccess(state, action) {
            state.list = action.payload;
        },

        getAdvancesSuccess(state, action) {
            state.advances = action.payload;
        },

        addAdvancesSuccess(state, action) {
            state.advances = [...state.advances, ...action.payload];
        },

        getOwnerSuccess(state, action) {
            state.owners = action.payload;
        },

        getOwnerEmailsSuccess(state, action) {
            state.ownerEmails = action.payload;
        },

        getTaxesSuccess(state, action) {
            state.taxes = action.payload;
        },

        getStatementDataSuccess(state, action) {
            state.statementData = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAccountancyList(year, month) {
    return async () => {
        try {
            const response = await axios.get(`/api/accounting/list/${year}/${month}`);
            const sortedData = Object.keys(response.data.data).sort().reduce((acc, key) => {
                acc[key] = response.data.data[key];
                return acc;
            }, {});
            dispatch(slice.actions.getAccountancySuccess(sortedData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getStatementData(id, year, month) {
    return async () => {
        try {
            const response = await axios.get(`/api/accounting/statement/${id}/${year}/${month}/json`);
            dispatch(slice.actions.getStatementDataSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function emptyAccountancyList() {
    return async () => {
        dispatch(slice.actions.getAccountancySuccess({}));
    };
}

export function sendEmail(id, year, month) {
    return async () => {
        try {
            await axios.get(`/api/accounting/sendrecap/${id}/${year}/${month}`);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const getAdvances = (page = 1) => async () => {
    try {
        const response = await axios.get(`/api/accounting/advances?page=${page}`);
        dispatch(slice.actions.getOwnerSuccess(response.data.owners));
        if(page === 1) {
            dispatch(slice.actions.getAdvancesSuccess(response.data.advances));
        } else {
            dispatch(slice.actions.addAdvancesSuccess(response.data.advances));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const getTaxes = (year, month) => async () => {
    try {
        const response = await axios.get(`/api/accounting/tax/${year}/${month}`);
        dispatch(slice.actions.getTaxesSuccess(response.data.stats));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const deleteAdvance = (id) => async () => {
    try {
        const response = await axios.delete(`/api/accounting/advances/${id}`);
        dispatch(slice.actions.getAdvancesSuccess(response.data.advances));
        dispatch(slice.actions.getOwnerSuccess(response.data.owners));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const createAdvance = (data) => async () => {
    try {
        const response = await axios.post(`/api/accounting/advances`, data);
        dispatch(slice.actions.getAdvancesSuccess(response.data.advances));
        dispatch(slice.actions.getOwnerSuccess(response.data.owners));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const updateOwnerRelatedEmails = (ownerId, emails) => async () => {
    try {
        const response = await axios.post(`/api/users/${ownerId}/emails`, { emails });
        dispatch(slice.actions.getOwnerEmailsSuccess(response.data.emails));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const getOwnerRelatedEmails = (ownerId) => async () => {
    try {
        const response = await axios.get(`/api/users/${ownerId}/emails`);
        dispatch(slice.actions.getOwnerEmailsSuccess(response.data.emails));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const resetOwnerRelatedEmails = () => async () => {
    dispatch(slice.actions.getOwnerEmailsSuccess([]));
}

export const decodeDocumentId = (documentId) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.get(`/api/accounting/document/${documentId}`);
        if(response.data.success) 
            return resolve(response.data.document);
        else 
            return reject(response.data.message);
    });
}